import { memo } from '../util/memo';
import Box from '@mui/material/Box';
import { SxProps, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export type LiveBadgeProps = {
  children?: JSX.Element;
  sx?: SxProps;
  onClick?: () => Promise<void>;
};

function LiveUnmemoized({ sx, onClick }: Omit<LiveBadgeProps, 'children'>) {
  const theme = useTheme();
  return (
    <Box
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        position: 'absolute',
        '&:hover': onClick
          ? {
              cursor: 'pointer',
            }
          : undefined,
        ...sx,
        background: theme.palette.error.vertical,
        borderRadius: '4px',
        px: '8px',
      }}
      onClick={onClick}
    >
      <Typography variant="body2" color="text.primary" sx={{ fontWeight: 700 }}>
        LIVE
      </Typography>
    </Box>
  );
}

const Live = memo(LiveUnmemoized);

function LiveBadgeUnmemoized({ children, sx, onClick }: LiveBadgeProps) {
  return (
    <>
      {children ? (
        <Box sx={{ position: 'relative' }}>
          {children}
          <Live sx={sx} onClick={onClick} />
        </Box>
      ) : (
        <Live sx={{ position: 'unset', ...sx }} onClick={onClick} />
      )}
    </>
  );
}
export const LiveBadge = memo(LiveBadgeUnmemoized);
