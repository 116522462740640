import { useEffect, useState } from 'react';
import { usePollLivestream } from '../mux/usePollLivestream';
import { LiveStream } from '@mux/mux-node/resources/video/live-streams';

export const useLivestream = (livestreamId?: string) => {
  const [livestream, setLivestream] = useState<LiveStream>();
  const { onPoll } = usePollLivestream(livestreamId);

  useEffect(() => {
    if (!livestreamId) {
      return;
    }

    const unsubscribe = onPoll(setLivestream);

    return () => {
      unsubscribe?.();
    };
  }, [livestreamId, onPoll]);

  return livestream;
};
