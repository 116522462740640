import { useMemo, ReactNode } from 'react';
import { LivestreamPlayer } from '../../components/livestream/mux/LivestreamPlayer';
import { ContentCarousel } from '../../components/ContentCarousel';

const BUTTON_SX = { height: '28px', width: '28px' };

export type UseLivestreamPlayerParams = {
  livestreamId?: string;
  playbackIds?: string[];
  placeholder?: ReactNode;
};

export const useLivestreamPlayer = ({
  playbackIds,
  livestreamId,
  placeholder,
}: UseLivestreamPlayerParams) => {
  const livestreamCarousel = useMemo(() => {
    if (!playbackIds || !playbackIds.length || !livestreamId) {
      return placeholder ?? undefined;
    }
    const playbackIdsReversed = [...playbackIds].reverse();

    const carouselContent = playbackIdsReversed.map((playbackId, index) => {
      return (
        <LivestreamPlayer
          key={playbackId}
          livestreamId={livestreamId}
          playbackId={playbackId}
          isLatestPlayback={index === 0}
        />
      );
    });

    return (
      <ContentCarousel
        content={carouselContent}
        buttonSx={BUTTON_SX}
        bottom={0}
      />
    );
  }, [playbackIds, livestreamId, placeholder]);

  return livestreamCarousel;
};
