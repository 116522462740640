import { memo } from '../../util/memo';
import Stack from '@mui/material/Stack';
import { GradientIcon } from '../gradients/GradientIcon';
import { GradientTypography } from '../gradients/GradientTypography';
import CircleIcon from '@mui/icons-material/Circle';
import { useTheme } from '@mui/material/styles';

const OfflineUnmemoized = () => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        background: theme.palette.other.snackbar,
        borderRadius: '4px',
        pr: 1.5,
        pb: 0.5,
      }}
    >
      <GradientIcon
        IconComponent={CircleIcon}
        sx={{ height: '16px', mt: 0.5, mr: -0.5 }}
      />
      <GradientTypography>OFFLINE</GradientTypography>
    </Stack>
  );
};

export const Offline = memo(OfflineUnmemoized);
