import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { memo } from '../../../util/memo';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useCountViewers } from '../../../hooks/mux/useCountViewers';
import { useHover } from '../../../contexts/HoverContext';
import { useMemo } from 'react';
import { LiveBadge } from '../../LiveBadge';

export type ViewerCountProps = {
  playbackId?: string;
  livestreamId?: string;
  isLatestPlayback?: boolean;
};

export const TEXT_SX = {
  textShadow:
    'rgba(0, 0, 0, 1) 0px 0px 3px, rgba(0, 0, 0, 1) 0px 0px 5px, rgba(0, 0, 0, 0.5) 0px 0px 7px, rgba(0, 0, 0, 1) 0px 0px 10px',
  fontWeight: 700,
} as const;

const ViewerCountUnmemoized = ({
  playbackId,
  livestreamId,
  isLatestPlayback,
}: ViewerCountProps) => {
  const { isHovered } = useHover();
  const { viewerCount, viewsTotal } = useCountViewers({
    livestreamId,
    playbackId,
    isLatestPlayback,
  });

  const viewsIndicator = useMemo(() => {
    return !!viewerCount ? (
      <VisibilityOutlinedIcon sx={{ height: '18px', mt: '2px' }} />
    ) : !!viewsTotal ? (
      <Typography variant="subtitle2" sx={TEXT_SX}>{`VIEW${
        viewsTotal !== 1 ? 'S' : ''
      }`}</Typography>
    ) : (
      <></>
    );
  }, [viewerCount, viewsTotal]);

  const count = useMemo(() => {
    return (
      <Typography variant="subtitle2" sx={TEXT_SX}>
        {viewerCount || viewsTotal}
      </Typography>
    );
  }, [viewerCount, viewsTotal]);

  const liveBadge = useMemo(() => {
    return !!viewerCount && <LiveBadge />;
  }, [viewerCount]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ opacity: isHovered ? 1 : 0, mt: 1 }}
    >
      {liveBadge}
      <Stack
        alignItems="flex-start"
        direction={!!viewerCount ? 'row' : 'row-reverse'}
        spacing={!!viewerCount ? 0 : 1}
      >
        {viewsIndicator}
        {count}
      </Stack>
    </Stack>
  );
};

export const ViewerCount = memo(ViewerCountUnmemoized);
