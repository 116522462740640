import { useEffect, useState, useCallback } from 'react';
import { fetchTotalViews } from '../../util/mux/livestream/fetchTotalViews';
import { useLivestreamStatus } from '../livestream/useLivestreamStatus';
import { usePollLiveViewers } from './usePollLiveViewers';
import { isLive } from '../../util/mux/livestream/isLive';

export type UseCountViewersParams = {
  livestreamId?: string;
  playbackId?: string;
  isLatestPlayback?: boolean;
};

export const useCountViewers = ({
  livestreamId,
  playbackId,
  isLatestPlayback = true,
}: UseCountViewersParams) => {
  const [viewerCount, setViewerCount] = useState<number>();
  const [viewsTotal, setViewsTotal] = useState<number>();
  const { status } = useLivestreamStatus(livestreamId);
  const { onPoll } = usePollLiveViewers(playbackId);

  const isPlaybackLive = isLive(status, isLatestPlayback);

  const fetchViews = useCallback(async (playbackIdInternal: string) => {
    const totalViews = await fetchTotalViews(playbackIdInternal);
    setViewsTotal(totalViews);
  }, []);

  useEffect(() => {
    if (!playbackId || status === 'unknown') {
      return;
    }

    let unsubscribe: (() => void) | undefined;

    if (isPlaybackLive) {
      unsubscribe = onPoll(setViewerCount);
    } else {
      fetchViews(playbackId);
    }

    return () => {
      unsubscribe?.();
    };
  }, [playbackId, status, fetchViews, onPoll, isPlaybackLive]);

  return { viewerCount, viewsTotal };
};
