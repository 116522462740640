import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { memo } from '../../../util/memo';
import { useAuth } from '../../../contexts/AuthContext';
import { CopyTextOutlined } from '../../CopyTextOutlined';
import { useTheme } from '@mui/material/styles';
import { GradientTypography } from '../../gradients/GradientTypography';
import { LottieLoader } from '../../LottieLoader';
import { useMemo } from 'react';
import { MUX_LIVESTREAM_SERVER_URL } from '../../../../functions/src/util/mux/constants';
import { VideoPlaceholder } from '../../call/video/VideoPlaceholder';
import { Offline } from '../../call/Offline';
import { useLivestreamPlayer } from '../../../hooks/livestream/useLivestreamPlayer';

const LivestreamConfigurationUnmemoized = () => {
  const theme = useTheme();
  const { userData } = useAuth();

  const backgroundSx = {
    p: 6,
    background: theme.palette.background.elevation[12],
    borderRadius: '10px',
  };

  const { streamKey, playbackId, livestreamId } = userData?.hidden || {};

  const streamKeyExtracted = useMemo(() => {
    return streamKey ? streamKey[streamKey.length - 1] : undefined;
  }, [streamKey]);

  const placeholder = useMemo(() => {
    return <VideoPlaceholder placeholder={<Offline />} />;
  }, []);

  const livestreamPlayer = useLivestreamPlayer({
    playbackIds: playbackId ? [playbackId] : [],
    livestreamId,
    placeholder,
  });

  if (!userData || !streamKeyExtracted) {
    return <LottieLoader sx={{ height: '24px', width: '24px' }} />;
  }

  return (
    <Stack alignItems="center" spacing={4} width="100%">
      <Stack sx={backgroundSx} width="100%" spacing={4}>
        <Box>
          <GradientTypography
            variant="h6"
            sx={{ textAlign: 'center', textTransform: 'uppercase' }}
          >
            Connect to Streaming Software
          </GradientTypography>
          <Typography sx={{ textAlign: 'center' }}>
            Copy and paste the following credentials under stream settings.
          </Typography>
        </Box>
        <Stack spacing={4} sx={{ width: '100%' }}>
          <CopyTextOutlined
            title="Server Url"
            text={MUX_LIVESTREAM_SERVER_URL}
          />
          <CopyTextOutlined
            title="Stream Key"
            text={streamKeyExtracted}
            textSx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          />
        </Stack>
      </Stack>
      <Stack
        spacing={2}
        sx={{
          width: `100%`,
          ...backgroundSx,
          '& .str-video__video': {
            width: '100%',
            height: '100%',
          },
        }}
      >
        <Stack>
          <GradientTypography variant="h6" sx={{ textTransform: 'uppercase' }}>
            Preview
          </GradientTypography>
          <Typography variant="subtitle1">
            Begin streaming to see the preview below. This may take some time,
            thanks for your patience.
          </Typography>
        </Stack>
        {livestreamPlayer}
      </Stack>
    </Stack>
  );
};

export const LivestreamConfiguration = memo(LivestreamConfigurationUnmemoized);
