import { useLivestream } from './useLivestream';
import { LivestreamStatus } from '../../util/mux/livestream/isLive';

export const useLivestreamStatus = (
  livestreamId?: string,
): { status: LivestreamStatus } => {
  const livestream = useLivestream(livestreamId);
  const status = !livestream ? 'unknown' : livestream.status;

  return { status };
};
