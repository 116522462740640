import { useGlobalInterval } from '../../contexts/GlobalIntervalContext';
import { useCallback } from 'react';
import { fetchLivestream } from '../../util/mux/livestream/fetchLivestream';
import { LiveStream } from '@mux/mux-node/resources/video/live-streams';

export const POLLING_INTERVAL_MS = 15000 as const;

export const usePollLivestream = (livestreamId?: string) => {
  const { setGlobalInterval } = useGlobalInterval();

  const onPoll = useCallback(
    (callback: (livestream: LiveStream) => void) => {
      if (!livestreamId) {
        return;
      }
      return setGlobalInterval({
        id: livestreamId,
        intervalMs: POLLING_INTERVAL_MS,
        callback: async () => {
          const livestream = await fetchLivestream(livestreamId);
          callback(livestream);
        },
      });
    },
    [livestreamId, setGlobalInterval],
  );

  return { onPoll };
};
